// Custom error page could be referred to
// https://nextjs.org/docs/advanced-features/custom-error-page
// https://github.com/vercel/next.js/blob/canary/packages/next/pages/_error.tsx

import * as R from 'ramda';
import React, { useEffect } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useRouter } from 'next/router';

import * as Styled from './errorPage.styled';
import Svg403 from './403.svg';
import Svg404 from './404.svg';
import Svg500 from './500.svg';
import { CommunityHead } from '~/components';
import { CoreLayout } from '~/layouts';

const icons = {
  403: Svg403,
  404: Svg404,
  500: Svg500,
};

const errorMsgs = {
  403: '抱歉，您没有权限访问该页面',
  404: '您访问的页面不存在',
  500: '服务器异常，请稍后重试',
};

const ErrorPage = ({ statusCode, errorMsg, error = undefined }) => {
  const router = useRouter();
  const Icon = R.propOr(icons[500], statusCode)(icons);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  errorMsg = errorMsg || R.propOr('未知错误，请稍后重试', statusCode)(errorMsgs);
  errorMsg =
    '接下来的时间里，我们会做好Appsmith中文小组的文档、教程、版本更新、FAQ等汉化工作。由于我们的中文小组刚刚成立，非常欢迎有热情有经验网友，加入到管理队伍中来，努力将Appsmith中文小组打造成有特色的中文论坛。';
  const headProps = {
    description: '',
    keyword: '',
    title: `${statusCode || 'Appsmith 中文小组'}: ${errorMsg}`,
  };

  const buttonProps = {
    type: 'primary',
    icon: <ArrowLeftOutlined />,
    onClick: () => {
      router.push('/community', '/');
    },
  };

  return (
    <>
      <CommunityHead {...headProps} />

      <CoreLayout MainWrapper={Styled.Container}>
        <Styled.IconWrapper>
          <Icon />
        </Styled.IconWrapper>
        {/* <Styled.Message>{errorMsg}</Styled.Message> */}
        <Styled.Card>
          <Styled.msg>{errorMsg}</Styled.msg>
          <Styled.img>
            <div>
              <img
                style={{ width: 150, height: 150 }}
                src={`${process.env.NEXT_PUBLIC_CDN_URL}/images/code.jpg`}
                alt="请联系我"
              />
            </div>
          </Styled.img>
        </Styled.Card>

        <Button {...buttonProps}>返回首页</Button>
      </CoreLayout>
    </>
  );
};

export default ErrorPage;
