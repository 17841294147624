import { css } from 'styled-components';

import * as colors from '../colors';

export default css`
  .ant-breadcrumb {
    a:hover {
      color: ${colors.B1};
    }
  }
`;
