import styled from 'styled-components';
import LogoSvg from './appsmith_logo.svg';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Main = styled.div`
  flex: 1;
`;

export const FooterS = styled.div`
  padding: 50px 10px;
  display: flex;
  justify-content: space-evenly;
  background: #f7f8f9;
`;

export const FooterPartTitle = styled.div`
  border-left: 5px solid #f78336;
  border-top: 4px solid #f7f8f9;
  border-bottom: 4px solid #f7f8f9;
  border-radius: 5px;
  font-size: 20px;
  height: 25px;
  line-height: 18px;
  margin-left: -10px;
  padding-left: 10px;
`;

export const FooterBase = styled.div`
  padding: 12px;
  text-align: center;
  border-top: 1px solid #c0c0c04f;
  background: #f7f8f9;
`;
export const Logo = styled(LogoSvg)`
  cursor: pointer;
  transform: scale(1.5) translate(55px, 17px);
`;
