import { createContext } from 'react';

export const PageDataContext = createContext({
  data: {
    githubInfo: {
      prNum: 12,
      topicNum: 12,
      postNum: 12,
      contributorNum: 12,
    },
  },
});

PageDataContext.displayName = 'PageDataContext';
