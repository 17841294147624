import React, { useEffect, useState } from 'react';
import { createAppGlobalStyle } from '@tidb-community/ui';
import { api } from '@tidb-community/datasource';

import { ErrorPage } from '~/components';
import { MeContext } from '~/context';

const GlobalStyle = createAppGlobalStyle();
const _meData = {
  id: 3666056,
  username: 'admin',
  avatar_url: 'https://asktug.com/letter_avatar_proxy/v4/letter/t/4e50e8/90.png',
  is_staff: false,
  org: null,
  org_enroll: null,
  org_invitations: [],
};
const Error = (props) => {
  const [meData, setMeData] = useState(_meData);

  useEffect(() => {
    setMeData(_meData);
    // api
    //   .me()
    //   .then(({ data }) => setMeData(data))
    //   .catch(() => {});
  }, []);

  return (
    <>
      <GlobalStyle />
      <MeContext.Provider value={{ meData }}>
        <ErrorPage {...props} />
      </MeContext.Provider>
    </>
  );
};

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default Error;
