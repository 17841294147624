// import { Footer, Header } from '@pingcap-inc/tidb-community-site-components';
import { FC, PropsWithChildren } from 'react';
import * as Styled from './site.styled';
import { ErrorPage } from '~/components';
interface SiteLayoutProps {
  MainWrapper?: FC;
  backgroundColor?: string;
}

const SiteLayout = ({ MainWrapper = Styled.Main, backgroundColor, children }: PropsWithChildren<SiteLayoutProps>) => {
  return (
    <Styled.Container style={{ backgroundColor }}>
      {/* <Header /> */}
      <MainWrapper>
        {/* <ErrorPage statusCode={404} errorMsg={""}/> */}
        {children}
      </MainWrapper>
      {/* <Footer
        copyright="©2021 Appsmith Community"
        icp="粤ICP备2022037869号-2"
        icpUrl="https://beian.miit.gov.cn"
        number={
          (
            <span>
              <img src={`${process.env.NEXT_PUBLIC_CDN_URL}/images/beian.png`} alt="beian" />
              备案
            </span>
          ) as any
        }
        numberUrl="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802039111"
      /> */}
    </Styled.Container>
  );
};

export default SiteLayout;
