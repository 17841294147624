import blogClient from '../client';
import * as _ from 'lodash';
export * as posts from './posts';
export * as common from './common';
export * as users from './users';
export * as username from './username';

export type TPostOrigin = 'ORIGINAL' | 'REPOST';
export type TPostStatus = 'DRAFT' | 'PENDING' | 'REJECTED' | 'PUBLISHED' | 'ARCHIVED';

interface IRequestPagination {
  page?: number;
  size?: number;
}

export interface IMeta {
  id: number;
  name: string;
  slug: string;
}

export interface IAuthor {
  id: number;
  username: string;
  avatarURL: string;
}

export interface IResponsePostDetail {
  creatorId: number;
  modifierId: number;
  deletedAt: Date;
  publishedAt?: Date;
  createdAt: Date;
  lastModifiedAt?: Date;
  id: number;
  slug: string;
  version: string;
  title: string;
  summary: string;
  content: string;
  category: IMeta[];
  tags: IMeta[];
  origin: TPostOrigin;
  status: TPostStatus;
  sourceURL: string;
  coverImageURL?: string;
  rejectReason?: string;
  recommended: boolean;
  recommendAt?: Date;
  public: boolean;
  likes: number;
  comments: number;
  visits: number;
  author: IAuthor;
  attributes?: any;
}

export interface IPage {
  number: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export interface IResponseList<T> {
  data?: T[];
  content?: T[];
  page?: any;
  meta?: any;
}

interface IRequestPostList {
  tagID?: number;
  categoryID?: number;
  page: number;
  size: number;
}

interface ITag {
  id: number;
  name: string;
  slug: string;
}

interface ITagDetails extends ITag {
  description: string;
  posts: 0;
  createdAt: null;
}

interface ICategory {
  id: number;
  name: string;
  slug: string;
}

interface ICategoryDetails extends ICategory {
  description: string;
  posts: 0;
  createdAt: null;
}

interface IRequestPostDetail {
  slug: string;
  ip?: string;
  visit?: boolean;
  shareId?: string;
}

export enum EStatus {
  ALL = '',
  DRAFT = 'draft',
  PENDING = 'pending',
  REJECTED = 'rejected',
  PUBLISHED = 'published',
}

export interface IBlogAuthor {
  id: number;
  username: string;
  avatarURL: string;
}

export interface IBlogCategory {
  id: number;
  name: string;
  slug: string;
}

export async function getPostList(): Promise<IResponseList<IResponsePostDetail>> {
  const res = await blogClient.get(`/api/posts`);
  return res;
}

export async function getLatestList({ ...params }: IRequestPostList): Promise<any> {
  return await blogClient.get(`/api/posts`);
}

export async function postCreate(data: any): Promise<any> {
  return await blogClient.post(
    `/api/posts`,
    { data },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
}

export async function postUpdate(id:any, data: any): Promise<any> {
  const result = await blogClient.put(`/api/posts/${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return result
}
export async function postDelete(data: any): Promise<any> {
  return await blogClient.delete(`/api/posts/${data}`);
}
export async function getLatestLearning(): Promise<any> {
  return await blogClient.get(`/api/learning`);
}

export async function getBanner(): Promise<any> {
  return await blogClient.get(`/api/banner`);
}
export async function getWelcome(): Promise<any> {
  return await blogClient.get(`/api/page-welcome`);
}

export async function getFiles(): Promise<any> {
  return await blogClient.get(`/upload/files`);
}

export async function getOtherContent(): Promise<any> {
  return await blogClient.get(`/api/other`);
}

export async function getRecommendList({ ...params }: IRequestPostList): Promise<IResponseList<IResponsePostDetail>> {
  return await blogClient.get(`/api/posts/recommend`, { params });
}

export async function getAllPostList({ ...params }: IRequestPostList): Promise<IResponseList<IResponsePostDetail>> {
  return await blogClient.get(`/api/posts`, { params });
}

export async function _getPostBySlug({ slug, ip, shareId, visit }: IRequestPostDetail): Promise<IResponsePostDetail> {
  return await blogClient.get(`/api/posts/${slug}/detail`, {
    params: { shareId, visit },
    // headers: ip ? { 'X-Forwarded-For': ip } : undefined,
  });
}
export async function getPostBySlug({ slug, ip, shareId, visit }: IRequestPostDetail): Promise<any> {
  const res: IResponsePostDetail[] = await blogClient.get(`/api/posts?slug=${slug}`);
  return _.head(res);
}

export async function getTags(): Promise<any> {
  return await blogClient.get(`/api/tags`);
}

export async function getHotTags(): Promise<any> {
  const res =  await blogClient.get(`/api/tags`);
  return res
}

export async function getTagById(params: { id: number }): Promise<any> {
  return await blogClient.get(`/api/tags/${params.id}`);
}

export async function getTagBySlug(params: { slug: string }): Promise<ITagDetails> {
  const url = `/api/tags?slug=${params.slug}`;
  const res:any[] = await blogClient.get(url);
  return _.head(res);
}

export async function getCategories(params: { pagination?: IRequestPagination }): Promise<IResponseList<ICategory>> {
  return await blogClient.get(`/api/categories`);
}

export async function getCategoryById(params: { id: string }): Promise<ICategoryDetails> {
  return await blogClient.get(`/api/categories/${params.id}`);
}

export async function getCategoryBySlug(params: { slug: string }): Promise<ICategoryDetails> {
  const res:any[] = await blogClient.get(`/api/categories?slug=${params.slug}`);
  return _.head(res)
}

export async function getAudits(params: any): Promise<ICategoryDetails> {
  return await blogClient.get(`/api/audits`, { params });
}

export async function auditById(params: { id: number }): Promise<ICategoryDetails> {
  return await blogClient.get(`/api/audits/${params.id}`);
}
