import styled from 'styled-components';
import { mixins } from '@tidb-community/ui';

export const Container = styled.div`
  ${mixins.responsive()};
  ${mixins.flexCenter()};
  padding: 2rem 0;
  flex-direction: column;
  flex: 1;
`;

export const IconWrapper = styled.div`
  svg {
    width: 30vw;
    max-width: 280px;
    min-width: 160px;
  }
`;

export const Message = styled.div`
  ${mixins.typography('p1')};
  margin: 2.5rem 13rem;
`;

export const Card = styled.div`
  ${mixins.typography('p1')};
  margin: 1.5rem 13rem;
  // border: 1px solid pink;
  display: flex;
`;

export const msg = styled.div`
  ${mixins.typography('p1')};
  padding: 20px 30px;
  width: 70%;
  text-indent: 2em;
  letter-spacing: 3px;
  line-height: 31px;
  font-size: 17px;
`;
export const img = styled.div`
  ${mixins.typography('p1')};
  padding: 20px;
  width: 30%;
  text-align: center, &>img {
    border-radius: 10px;
    box-shadow: 0 1px 2px -2px #00000014, 0 3px 6px #0000000d, 0 5px 12px 4px #0000000d;
  }
`;
